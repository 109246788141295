var elec_min = 0;
var elec_max = 1000;
var gas_min = 0;
var gas_max = 1000;

document.addEventListener('DOMContentLoaded', function () {
    $('input[name="amount_elec"]').TouchSpin({
        min: elec_min,
        max: elec_max,
        stepinterval: 1,
        maxboostedstep: 1,
        //prefix: '€',
        buttondown_class: 'btn btn-lg btn-default supplier-button-color',
        buttonup_class: 'btn btn-lg btn-default supplier-button-color',
    }).on('change', function () {calcAmountTotal('');});
    $('input[name="amount_gas"]').TouchSpin({
        min: gas_min,
        max: gas_max,
        stepinterval: 1,
        maxboostedstep: 1,
        //prefix: '€',
        buttondown_class: 'btn btn-lg btn-default supplier-button-color',
        buttonup_class: 'btn btn-lg btn-default supplier-button-color',
    }).on('change', function () {calcAmountTotal('');});

    $('#amount_elec, #amount_gas').keyup(function(event){
        calcAmountTotal(event, this);
    });
});

function calcAmountTotal() {
    var amountElectricityField = $('#amount_elec');
    var amountElectricity = Math.abs(amountElectricityField.val());
    var amountGasField = $('#amount_gas');
    var amountGas = Math.abs(amountGasField.val());
    var amountTotal;

    if (isNaN(amountElectricity))
    {
        amountElectricity = 0;
    }
    if (isNaN(amountGas))
    {
        amountGas = 0;
    }

    amountTotal = amountElectricity + amountGas;
    if (amountTotal)
    {
        $('#amounttotal').val('€ ' + amountTotal + ',-');
    }
}
