$('#contract_status').change(function () {
    if (this.value === 'cancel')
    {
        $('.new-readings').hide();
        $('.new-address').hide();
    }
    else
    {
        $('.new-readings').show();
        $('.new-address').show();
    }
});