document.addEventListener('DOMContentLoaded', function () {

    $('#contracts .show_current_tariffs').change(function () {
        var params = new URLSearchParams(window.location.search);
        params.set('actualprices', ($(this).prop('checked') ? '1' : '0'));
        params.set('showmodal', ($(this).prop('checked') ? '1' : '0'));
        window.location.href = location.protocol + '//' + location.host + location.pathname +
            '?' + params.toString() + '#' + $(this).data('hash');
    });

    if (window.location.href.includes('showmodal'))
    {
        $('.tariff-modal').modal('show').addClass('fade');
    }

    $('.btw_switch').change(function () {
        var params = new URLSearchParams(window.location.search);
        var hash = $(this).data('hash');
        params.set('btw', ($(this).prop('checked') ? 'true' : 'false'));
        window.location.href = location.protocol + '//' + location.host + location.pathname +
            '?' + params.toString() + (hash ? '#' + hash : '');
    });

    $('#show_inactive_agreements').change(function () {
        if ($(this).prop('checked')) {
            $('.inactive-agreement').attr('style', 'display:block');
        }
        else {
            $('.inactive-agreement').attr('style', 'display:none');
        }
    });

    $('#contracts .contract-search').on('keyup', function() {
        var value = $(this).val().toLowerCase();
        $('#contracts .contract-line').filter(function() {
            $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
        });
    });
});
