document.addEventListener('DOMContentLoaded', function () {
    $('#reading-return').change(function() {
        if(this.checked) {
            $('#reading-return-input').show();
        } else {
            $('#reading-return-input').hide();
        }
    });

    $('#force-readings').change(function() {
        if(this.checked) {
            $('#reading-input').show();
        } else {
            $('#reading-input').hide();
        }
    });

    $('#submit-readings input').change(function() {
        $('button#submit-button').attr('disabled', false);
    }).keyup(function() {
        if ($(this).val()) {
            $('button#submit-button').attr('disabled', false);
        }
    });

    $('#extra_photos_1 .button-icon').click(function() {
        $('#extra_photos_2').show();
        $(this).hide();
    });

    $('#extra_photos_2 .button-icon').click(function() {
        $('#extra_photos_3').show();
        $(this).hide();
    });

    $('#extra_photos_3 .button-icon').click(function() {
        $('#extra_photos_4').show();
        $(this).hide();
    });
});

