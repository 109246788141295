document.addEventListener('DOMContentLoaded', function () {

    $('input#elec_vat').on('click', function () {
        if ($(this).prop('checked')) {
            vat = 'true';
        } else {
            vat = 'false';
        }
        getData('electricity', selectedDate, period, vat, elec_chart);
    });

    $('input#gas_vat').on('click', function () {
        if ($(this).prop('checked')) {
            vat = 'true';
        } else {
            vat = 'false';
        }
        getData('gas', selectedDate, period, vat, gas_chart);
    });

});