var debugOpened;
if (getCookie('debug_opened'))
{
    debugOpened = JSON.parse(getCookie('debug_opened'));
}
if (! debugOpened)
{
    debugOpened = {};
}

$(document.body).on('click', '.debug_opened', function(e)
{
    e.stopImmediatePropagation();
    // close the debug div
    var name = $(this).attr('name');
    $(this).removeClass('debug_opened').addClass('debug_closed');
    debugOpened[name.toString()] = 'false';
    setCookie('debug_opened', JSON.stringify(debugOpened));
    //console.log(debugOpened);
}).on('click', '.debug_closed', function(e)
{
    e.stopImmediatePropagation();
    // open the debug div
    var name = $(this).attr('name');
    $(this).removeClass('debug_closed').addClass('debug_opened');
    debugOpened[name.toString()] = 'true';
    setCookie('debug_opened', JSON.stringify(debugOpened));
    //console.log(debugOpened);
}).on('click', '.debug_openall', function(e)
{
    e.stopImmediatePropagation();
    $(this).parent().find('.debug_closed').removeClass('debug_closed').addClass('debug_opened');
    $(this).removeClass('debug_openall').addClass('debug_closeall');
    //console.log('openall');
}).on('click', '.debug_closeall', function(e)
{
    e.stopImmediatePropagation();
    $(this).parent().find('.debug_opened').removeClass('debug_opened').addClass('debug_closed');
    $(this).removeClass('debug_closeall').addClass('debug_openall');
    //console.log('closeall');
});