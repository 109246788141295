document.addEventListener('DOMContentLoaded', function () {
    $('#delete_account').on('change', function () {
        if ($(this).is(':checked'))
        {
            $('.delete_account_submit').attr('disabled', false);
        }
        else
        {
            $('.delete_account_submit').attr('disabled', true);
        }
    });
});
