document.addEventListener('DOMContentLoaded', function () {

    $('#daily_readings_electricity').change(function () {
        if (this.checked)
        {
            $('#quarter_readings_electricity').prop('checked', false);
        }
    });

    $('#quarter_readings_electricity').change(function () {
        if (this.checked)
        {
            $('#daily_readings_electricity').prop('checked', false);
        }
    });

    $('#daily_readings_gas').change(function () {
        if (this.checked)
        {
            $('#quarter_readings_gas').prop('checked', false);
        }
    });

    $('#quarter_readings_gas').change(function () {
        if (this.checked)
        {
            $('#daily_readings_gas').prop('checked', false);
        }
    });

});