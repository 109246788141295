/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');


/** FONTAWESOME **/
require('@fortawesome/fontawesome-free/js/all');

/** PLUGINS **/
window.Highcharts = require('highcharts/highstock');
require('highcharts/modules/map')(Highcharts);
require('highcharts/modules/drilldown')(Highcharts);
require('highcharts/modules/no-data-to-display')(Highcharts);
require('./_libs/bootstrap-touchspin/dist/jquery.bootstrap-touchspin');
require('bootstrap-datepicker/dist/js/bootstrap-datepicker');
require('bootstrap-datepicker/dist/locales/bootstrap-datepicker.nl.min');
// input validation: https://github.com/nosir/cleave.js/
var Cleave;
Cleave = require('cleave.js/dist/cleave.min.js');
require('cleave.js/dist/addons/cleave-phone.nl.js');
/** CUSTOM GLOBAL JS **/
require('./global');

/** CUSTOM JS **/
require('./usage/usage');
require('./changes/changes');
require('./settings/settings');
require('./contracts/contracts');
require('./invoices/invoices');
require('./salesperson/salesperson');
require('./admin/admin');
require('./debug');
require('./auth/delete-account');


// window.Vue = require('vue');
//
// /**
//  * The following block of code may be used to automatically register your
//  * Vue components. It will recursively scan this directory for the Vue
//  * components and automatically register them with their "basename".
//  *
//  * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
//  */
//
// // const files = require.context('./', true, /\.vue$/i);
// // files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));
//
// Vue.component('example-component', require('./components/ExampleComponent.vue').default);
//
// /**
//  * Next, we will create a fresh Vue application instance and attach it to
//  * the page. Then, you may begin adding components to this application
//  * or customize the JavaScript scaffolding to fit your unique needs.
//  */
//
// const app = new Vue({
//     el: '#app',
// });

$(function () {
    $('[data-toggle="popover"]').popover();
});
