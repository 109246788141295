document.addEventListener('DOMContentLoaded', function () {

    $('#download-button').click(function () {
        $('.alert').hide();
    });

    $('#consolidated').click(function() {
        if ($(this).is(':checked')) {
            setCookie('consolidated', true);
            $('.fees_normal').fadeOut(500, function () {
                $('.fees_consolidated').fadeIn(500);
            });
        }
        else
        {
            setCookie('consolidated', false);
            $('.fees_consolidated').fadeOut(500, function () {
                $('.fees_normal').fadeIn(500);
            });
        }
    });



});
